import httpService from './httpService';

const getOrders = (criteria = {}) => {
  return httpService.FetchOrderList(criteria);
};

const getOrderById = (criteria = {}) => {
  return httpService.FetchOrderInfo(criteria);
};

const deleteOrder = (id) => {
  return httpService.DeleteOrder(id);
};

const printOrder = (id) => {
  return httpService.PrintOrderInfo(id)
};

const createOrderStore = (id) => {
  return httpService.CreateOrderStore(id);
};

const reviseOrder = (id) => {
  return httpService.ReviseOrder(id);
};

const syncOrder = (id) => {
  return httpService.SyncOrder(id);
};

const editOrder = (id, data) => {
  return httpService.UpdateOrder({}, data);
};

const importOrder = (id, data) => {
  
  let fd = new FormData();

  fd.append('file', data);
  
  return httpService.ImportOrderInfo(id, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};

const updateOrderStatus = (id, data) => {
  return httpService.UpdateOrderStatus(  { id: id.id }, data);
};

const addOrderAttachment = (id, data) => {
  let fd = new FormData();
  data.map((file, index) => {
    return fd.append('file', file)
  });
  return httpService.CreateOrderAttachment(
    id, fd,

    {
      headers: {
        'Content-Type': 'multipart/form-data',

      },

    });
};

const getOrderAttachment = (criteria = {}) => {
  return httpService.FetchOrderAttachment(criteria, {responseType: 'arraybuffer'});
};

const deleteOrderAttachment = (data) => {
  return httpService.DeleteOrderAttachment(data);
};

const cancel = (id) => {
 
   return httpService.CancelTransaction(id);

};

const confirm = (id) => {

  return httpService.ConfirmOrder(id);
  
};

const accept = (id) => {

  return httpService.AcceptOrderStatusUpdate(id);
  
};
const submitOrder = (data) => {
  return httpService.SubmitOrder(data);
};

const importDeliveryInvoices = (id, data) => {
  
  let fd = new FormData();

  for (let i = 0; i < data.length; i++) {
    fd.append('files', data[i])
  };

  return httpService.UploadOrderInvoices(id, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};

export {
  getOrders,
  getOrderById,
  deleteOrder,
  updateOrderStatus,
  addOrderAttachment,
  importOrder,
  printOrder,
  getOrderAttachment,
  deleteOrderAttachment,
  cancel,
  editOrder,
  reviseOrder,
  confirm,
  accept,
  submitOrder,
  syncOrder,
  createOrderStore,
  importDeliveryInvoices
};
