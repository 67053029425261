import httpService from './httpService';

const getAllContracts = (criteria = {}) => {
  return httpService.FetchContractList(criteria);
};

const getAllInfoContracts = (criteria = {}) => {
  return httpService.FetchContracts(criteria);
};

const editContractInfo = (id, data) => {
  return httpService.UpdateContractInfo({},data)
};

const getAllContractsProductsUnits = (criteria = {}) => {
  return httpService.FetchContractsProductsUnits(criteria);
};

const deleteContract = (id) => {
  return httpService.ClearContractData(id);
};
// const getAllContractsProducts = (criteria = {}) => {
//   return httpService.CompareContractsAllProducts(criteria);
// };

const compareContracts = (criteria = {}) => {
  return httpService.CompareContracts({},criteria);
};


const getAllContractsProducts = (val,criteria = {}) => {
  return httpService.FetchContractsProducts(val,criteria);
};

const getAllContractsProductUnits = (product,criteria = {}) => {
  return httpService.FetchContractsProductUnits(product,criteria);
};

const getAllContractsPackages = (product,criteria = {}) => {
  return httpService.FetchPackages(product,criteria);
};

const clearContractsCache = (criteria = {}) => {
  return httpService.ClearContractsCache({},criteria);
};


const approveContract= (id) => {
  return httpService.ApproveContract(id);
};

const hideContract = (id) => {
  return httpService.HideContract(id);
};

export {
  getAllContracts,
  getAllInfoContracts,
  deleteContract,
  getAllContractsProducts,
  getAllContractsProductsUnits,
  getAllContractsProductUnits,
  compareContracts,
  getAllContractsPackages,
  clearContractsCache,
  approveContract,
  editContractInfo,
  hideContract
};
