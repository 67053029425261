import React from 'react';
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './styles.scss';

const TableActions = (props) => {
    return (
        <div className={'table-actions'}>
            {props.config.edit.enabled && <EditIcon className={'edit-table-row'} onClick={props.config.edit.callback}/>}
            {props.config.delete.enabled &&  <DeleteOutlineIcon onClick={props.config.delete.callback}/>}
            {props.config.save.enabled && <SaveIcon onClick={props.config.save.callback}/>}
            {props.config.copy?.enabled && <ContentCopyIcon onClick={props.config.copy.callback}/>}
            {props.config.close.enabled && <CancelIcon onClick={props.config.close.callback}/>}
            {props.config.quote && props.config.quote.enabled && <PlaylistAddIcon onClick={props.config.quote.callback}/>}
            {props.config.createOrderStore?.enabled && <PlaylistAddIcon onClick={props.config.createOrderStore.callback}/>}
        </div>
    );
}

export default TableActions;