import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersProductInventory, newProductInventory  } from '../../helpers/productsInventoryConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import { deleteProduct, addProduct } from '../../services/productsService';
import { getAllVesselItems, editVesselStoreItem } from '../../services/vesselsService';
import { getAuthorizationMappings } from '../../services/authorizationsService';
import FilterVesselStoreTypes from '../../components/filterVesselStoreTypes';
import { ProductsInventoryTableFilter } from '../../components/productsInventoryTableFilter';
import FilterProductCategories from '../../components/filterProductCategories';
import FilterCodingSystems from "../../components/filterCodingSystems";
import Switch from '@mui/material/Switch';


import './_productsInventory.scss';
import t from '../../helpers/languages';

const ProductsInventory = (props) => {

  const productInventoryInitObject = () => {
    return {
      id: '?',
      orderItem: { name: null, id: null },
      rfid: null,
      quantity: null,
      lastScan: null,
      status: null,
      vessel: { name: null, id: null },
      notes: null,
    }
  };

  const classes = useStyles();

  const userState = props.userState && props.userState.user;

  const filters = localStoreGetObj('productInventoryFilter');

  const [productsStateInventory, setProductsStateInventory] = useState({
    productsInventory: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    productInventoryEditIndex: -1,
    productInventoryEditObject: productInventoryInitObject(),
    productInventoryCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};

  let vesselId = null;

  const pathName = window.location.pathname;
  const regex = /(\d+)/gm;
  let m;
  m = regex.exec(pathName);
  vesselId = m[0];
  

  filtersData = {
    ...productsStateInventory.filters,
    vesseStoreType: filters && filters.vesseStoreType && filters.vesseStoreType.id,
    vesselStoreStatus: filters && filters.vesselStoreStatus && filters.vesselStoreStatus.status,
    vessel:vesselId,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllVesselItems(filtersData).then(res => {
      const { results } = res.data;

      setProductsStateInventory({
        ...productsStateInventory,
        productsInventory: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/productsInventory', userState.userRole)
        }
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setProductsStateInventory({
        ...productsStateInventory,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setProductsStateInventory({
      ...productsStateInventory,
      isLoading: true
    });
    const newCriteria = {
      ...productsStateInventory.filters,
      per_page: productsStateInventory.page.per_page,
      page: newPage + 1
    };
    getAllVesselItems(newCriteria).then(res => {
      setProductsStateInventory({
        ...productsStateInventory,
        isLoading: false,
        productsInventory: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setProductsStateInventory({
      ...productsStateInventory,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...productsStateInventory.filters,
      page: 1,
      per_page: perPage,
    };

    getAllVesselItems(newCriteria).then(res => {
      setProductsStateInventory({
        ...productsStateInventory,
        isLoading: false,
        productsInventory: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setProductsStateInventory({
        ...productsStateInventory,
        isLoading: false,
      });
    });
  };

  const handleEditProductInventory = (event, productIndex) => {

    event.stopPropagation();

    const productInventoryEditObject = Object.assign({}, productsStateInventory.productsInventory[productIndex]);


    setProductsStateInventory({
      ...productsStateInventory,
      productInventoryEditIndex: productIndex,
      productInventoryEditObject: productInventoryEditObject
    });

  };

  const onEditableProductChange = (event, property) => {
    
    let newValue = '';
    if (property === "isDiscountable" || property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditProductItem = Object.assign({}, productsStateInventory.productInventoryEditObject);
    newEditProductItem[property] = newValue;

    setProductsStateInventory({
      ...productsStateInventory,
      productInventoryEditObject: newEditProductItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveProductInventory = (event, props) => {
    event.preventDefault();
    setProductsStateInventory({
      ...productsStateInventory,
      isLoading: true
    });
    const newEditProductItem = Object.assign({}, productsStateInventory.productInventoryEditObject);

    if (validator.current.allValid()) {
      editVesselStoreItem(newEditProductItem.id, newEditProductItem)
        .then(re => {
          const editedProduct = re.data;
          setProductsStateInventory(oldProducts => {
            return {
              ...oldProducts,
              productsInventory: oldProducts.productsInventory.map(product => {
                return product.id === editedProduct.id
                  ? re.data : product
              }),
              productInventoryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setProductsStateInventory({
            ...productsStateInventory,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setProductsStateInventory({
        ...productsStateInventory,
        isLoading: false,
      });
    }

  };

  const handleCloseEditProductInventory = (e, index) => {
    setProductsStateInventory({
      ...productsStateInventory,
      productInventoryEditIndex: -1
    })
  };

  const handleDeleteProductInventory = (event, index) => {

    const productForDeletion = productsStateInventory.productsInventory[index];
    setProductsStateInventory({
      ...productsStateInventory,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Product',
        onCancel: () => {
          setProductsStateInventory({
            ...productsStateInventory,
            confirmDialog: {
              ...productsStateInventory.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteProduct(productForDeletion.id).then(() => {
            const newProducts = [...productsStateInventory.productsInventory];
            newProducts.splice(index, 1);
            setProductsStateInventory({
              ...productsStateInventory,
              productsInventory: newProducts,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewProductInventory = (e) => {
    e.preventDefault();

    setProductsStateInventory({
      ...productsStateInventory,
      productInventoryCreateObject: newProduct()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewProduct = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newProduct = {};
    newProduct.name = productsStateInventory.productInventoryCreateObject.name;
    newProduct.code = productsStateInventory.productInventoryCreateObject.code;
    newProduct.category = productsStateInventory.productInventoryCreateObject.category;
    newProduct.coding = productsStateInventory.productInventoryCreateObject.coding;
    newProduct.isUnresolved = productsStateInventory.productInventoryCreateObject.isUnresolved;
    newProduct.isDiscountable = productsStateInventory.productInventoryCreateObject.isDiscountable;

    if (validator.current.allValid()) {
      addProduct(newProduct)
        .then(res => {
          setProductsStateInventory({
            ...productsStateInventory,
            productsInventory: [...productsStateInventory.productsInventory, res.data],
            productInventoryCreateObject: null,
            productInventoryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setProductsStateInventory({
            ...productsStateInventory,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewProductObject = (e, property) => {
   
    let newValue='';
    if (property === "isDiscountable" || property === "isUnresolved") {
      newValue = e.target.checked ? true : false;
    }
    else {
      newValue = e.target.value;
    }

    const newEditProductItem = Object.assign({}, productsStateInventory.productInventoryCreateObject);
    newEditProductItem[property] = newValue;

    setProductsStateInventory({
      ...productsStateInventory,
      productInventoryCreateObject: newEditProductItem
    });
  };

  const filterData = (data) => {
    setProductsStateInventory({
      ...productsStateInventory,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...productsStateInventory.filters,
      store: data && data.vesseStoreType && data.vesseStoreType.id,
      status: data && data.vesselStoreStatus && data.vesselStoreStatus.status,
      vessel:vesselId,
      per_page: criteria.per_page,
      page: 1 
    };

    getAllVesselItems(filtersData).then(res => {
      const { results } = res.data;

      setProductsStateInventory({
        ...productsStateInventory,
        productsInventory: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/productsInventory', userState.userRole)
        }
      });
      localStoreSetObj('productFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setProductsStateInventory({
        ...productsStateInventory,
        isLoading: false,
      });
    });

  };

  const selectedCodingSystem = (data, action) => {

    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? productsStateInventory.productInventoryEditObject
        : productsStateInventory.productInventoryCreateObject
    );

    newEditProductItem.coding = data.codingSystem;

    setProductsStateInventory({
      ...productsStateInventory,
      [
        action === 'edit'
          ? 'productInventoryEditObject'
          : 'productInventoryCreateObject'
      ]: newEditProductItem
    });
  };


  const selectedProductCategory = (data, action) => {

    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? productsStateInventory.productInventoryEditObject
        : productsStateInventory.productInventoryCreateObject
    );

    newEditProductItem.category = data.category;

    setProductsStateInventory({
      ...productsStateInventory,
      [
        action === 'edit'
          ? 'productInventoryEditObject'
          : 'productInventoryCreateObject'
      ]: newEditProductItem
    });
  };

  const selectedVesseStoreType = (data, action) => {

    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? productsStateInventory.productInventoryEditObject
        : productsStateInventory.productInventoryCreateObject
    );

    newEditProductItem.store = data.vesseStoreType;

    setProductsStateInventory({
      ...productsStateInventory,
      [
        action === 'edit'
          ? 'productInventoryEditObject'
          : 'productInventoryCreateObject'
      ]: newEditProductItem
    });
  }

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={false}
     

    />;
  };
  
  const tableFilterComponent = () => {
    return <ProductsInventoryTableFilter
      vesseStoreType={(filters && filters.vesseStoreType) ? filters.vesseStoreType : productsStateInventory.filters.vesseStoreType}
      vesselStoreStatus={(filters && filters.vesselStoreStatus) ? filters.vesselStoreStatus : productsStateInventory.filters.vesselStoreStatus}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersProductInventory}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(productsStateInventory.productsInventory) && productsStateInventory.productsInventory.map((product, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (productsStateInventory.productInventoryEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{product.orderItem?.productDescription}</TableCell>
                    <TableCell>{product.rfid}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.lastScanned&&product.lastScanned.slice(0, 10)}</TableCell>
                    <TableCell>{product.status}</TableCell>
                    <TableCell>{product.store?.name}</TableCell>
                    <TableCell>{product.notes}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {product.orderItem?.productDescription}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {product.rfid}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={productsStateInventory.productInventoryEditObject.quantity || ''}
                          onChange={e => onEditableProductChange(e, 'quantity')}
                          onBlur={() => validator.current.showMessageFor('quantity')}
                        />{validator.current.message('quantity', productsStateInventory.productInventoryEditObject.quantity, 'required|numeric|min:0,num')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        {product.lastScanned}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {product.status}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterVesselStoreTypes
                          vesseStoreType={productsStateInventory.productInventoryEditObject.store || null}
                          getSelectedVesseStoreType={(data) => selectedVesseStoreType(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {product.notes}
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: productsStateInventory.productInventoryEditIndex !== index,
                    callback: (e) => handleEditProductInventory(e, index)
                  },
                  delete: {
                    enabled: false,
                    callback: () => null
                  },
                  close: {
                    enabled: productsStateInventory.productInventoryEditIndex === index,
                    callback: (e) => handleCloseEditProductInventory(e, index)
                  },
                  save: {
                    enabled: productsStateInventory.productInventoryEditIndex === index,
                    callback: (e) => handleSaveProductInventory(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          productsStateInventory.productInventoryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={productsStateInventory.productInventoryCreateObject.code || ''}
                  onChange={e => onEditNewProductObject(e, 'code')}
                  onBlur={() => validator.current.showMessageFor('code')}
                />{validator.current.message('code', productsStateInventory.productInventoryCreateObject.code, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={productsStateInventory.productInventoryCreateObject.name || ''}
                  onChange={e => onEditNewProductObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', productsStateInventory.productInventoryCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterProductCategories
                  category={productsStateInventory.productInventoryCreateObject.category || null}
                  getSelectedProductCategory={(data) => selectedProductCategory(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCodingSystems
                  codingSystem={productsStateInventory.productInventoryCreateObject.coding}
                  getSelectedCodingSystem={(data) => selectedCodingSystem(data, 'create')}
                />{validator.current.message('coding', productsStateInventory.productInventoryCreateObject.coding&&productsStateInventory.productInventoryCreateObject.coding.id, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={productsStateInventory.productInventoryCreateObject.isUnresolved}
                        onChange={e => onEditNewProductObject(e, 'isUnresolved')}
                        name="isUnresolved"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="ΙsUnresolved"
                  />
                </FormControl>
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={productsStateInventory.productInventoryCreateObject.isDiscountable}
                        onChange={e => onEditNewProductObject(e, 'isDiscountable')}
                        name="isDiscountable"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="ΙsDiscountable"
                  />
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setProductsStateInventory({ ...productsStateInventory, productInventoryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewProduct()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={props.location.vessel?t('PRODUCTS_INVENTORY') + ' Vessel: ' + props.location.vessel:t('PRODUCTS_INVENTORY')}
        pagination={{
          total: productsStateInventory.page.total || 0,
          page: productsStateInventory.page.page - 1 || 0,
          perPage: productsStateInventory.page.per_page || 10,
          count: productsStateInventory.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={productsStateInventory.authorizedActions.create}
        onNewRowClick={handleAddNewProductInventory}
        newRowBtnEnabled={!productsStateInventory.productInventoryCreateObject}
        confirmDialog={productsStateInventory.confirmDialog.message.length > 0 ? productsStateInventory.confirmDialog : productsStateInventory.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        breadcrump={breadcrump}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={productsStateInventory.isLoading}
      />
    </>
  );
};


export default ProductsInventory;
