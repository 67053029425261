import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getVesselStoreStatuses } from '../../services/vesselsService';

export default function FilterVesselStoreStatuses({ vesselStoreStatus: initialvesselStoreStatus, getSelectedVesselStoreStatus }) {

  const [vesselStoreStatus, setvesselStoreStatus] = React.useState(0);
  const [vesselStoreStatuses, setVesselStoreStatuses] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setvesselStoreStatus(initialvesselStoreStatus && initialvesselStoreStatus.id !== null ? initialvesselStoreStatus : 0);
    setVesselStoreStatuses(initialvesselStoreStatus && initialvesselStoreStatus.id !== null ? [initialvesselStoreStatus] : []);
    getVesselStoreStatuses().then((res) => {
      setVesselStoreStatuses(res.data);
    }).catch((error) => {
    })
  }, [initialvesselStoreStatus]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    setSearch(value);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onvesselStoreStatusSelectionChange = (event, values) => {
    setvesselStoreStatus(values ? values : '');
    const selectedVesselStoreStatus = {
      vesselStoreStatus: values ? values :  null, // return null when the value is empty string
    };
    getSelectedVesselStoreStatus(selectedVesselStoreStatus);
  };


  return (
    <div>
      <Autocomplete
        id={"find-vesselStoreStatus" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'vesselStoreStatus')}
        onChange={onvesselStoreStatusSelectionChange}
        value={vesselStoreStatuses.find(v => v.id === vesselStoreStatus.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.filter !== null ? option.filter : option.status)}
        options={vesselStoreStatuses !== null ? vesselStoreStatuses : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('STATUS')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}